

import { defineComponent } from 'vue';
import { PhysicsVelocity } from '../BabylonExamples/PhysicsVelocity';

export default defineComponent({
	name: 'PBR',
	mounted () {

		const canvas = document.querySelector('canvas') as HTMLCanvasElement;
		new PhysicsVelocity(canvas);
	
	}
});
