import {
	Scene,
	Engine,
	SceneLoader,
	FreeCamera,
	HemisphericLight,
	Vector3,
	CannonJSPlugin,
	MeshBuilder,
	PhysicsImpostor,
	AbstractMesh,
	CubeTexture,
	PBRMaterial,
	Texture,
	StandardMaterial,
	Mesh,
} from '@babylonjs/core';
import '@babylonjs/loaders';
import * as CANNON from 'cannon';
  
export class PhysicsVelocity {

    scene: Scene;
    engine: Engine;
    box!: AbstractMesh;
    ground!: AbstractMesh;
    spheres: Array<AbstractMesh> = [];
    camera!: FreeCamera;
  
    constructor (private canvas: HTMLCanvasElement) {

    	this.engine = new Engine(this.canvas, true);
    	this.engine.setHardwareScalingLevel(0.7);
    	this.scene = this.CreateScene();
    	this.CreateEnvironment();
    	this.CreateImpostors();
    	this.CreateRocket();
  
    	this.engine.runRenderLoop(() => {

    		this.scene.render();
    	
    	});
    
    }
  
    CreateScene (): Scene {

    	const scene = new Scene(this.engine);
    	
    	const envTex = CubeTexture.CreateFromPrefilteredData('./environment/sky.env', scene);
    	new HemisphericLight('hemi', Vector3.Zero(), scene);
    	envTex.gammaSpace = false;
    	envTex.rotationY = Math.PI / 2;
    	scene.environmentTexture = envTex;
    	scene.createDefaultSkybox(envTex, true, 1000, 0.25);

    	scene.onPointerDown = (evt) => {

    		if (evt.button === 0) this.engine.enterPointerlock();
		  if (evt.button === 1) this.engine.exitPointerlock();
		
    	};

    	this.camera = new FreeCamera('camera', new Vector3(0,10, -18), this.scene);
    	this.camera.attachControl();
    	this.camera.minZ = 0.5;
    	this.camera.rotation = new Vector3(0.5, 0, 0);

    	scene.enablePhysics(
    		new Vector3(0, -9.81, 0),
    			new CannonJSPlugin(true, 10, CANNON)
    	);
    	return scene;
    
    }
  
    async CreateEnvironment (): Promise<void> {

    	const { meshes } = await SceneLoader.ImportMeshAsync(
    		'',
    		'./models/',
    		'Prototype_Level_No_Ramp.glb',
    		this.scene
    	);
    
    	this.AddDadDecal(meshes[1], new Vector3(15, 15, 15), Vector3.Zero(), Vector3.Up(), 0);
    
    }

    AddDadDecal (mesh: AbstractMesh, size: Vector3, position: Vector3, normal: Vector3, angle: number): Mesh {

    	const decalMaterial = new StandardMaterial('decalMat', this.scene);
    	decalMaterial.diffuseTexture = new Texture('./environment/dad.png', this.scene);
    	decalMaterial.diffuseTexture.hasAlpha = true;
    	decalMaterial.zOffset = -2;

    	const decal = MeshBuilder.CreateDecal('decal', mesh, 
    	{ position: position, normal: normal, size: size, angle: angle });
    	decal.material = decalMaterial;

    	return decal;
    
    }
  
    CreateImpostors (): void {

    	this.box = MeshBuilder.CreateBox('box', { size: 2 });
    	this.box.position = new Vector3(8, 8, 0);
  
    	this.box.physicsImpostor = new PhysicsImpostor(
    		this.box,
    		PhysicsImpostor.BoxImpostor,
    		{ mass: 1, restitution: 1 }
    	);

    	this.box.material = this.CreateAshpalt();
  
    	this.ground = MeshBuilder.CreateGround('ground', {
    		width: 40,
    		height: 40,
    	});
  
    	this.ground.isVisible = false;
  
    	this.ground.physicsImpostor = new PhysicsImpostor(
    		this.ground,
    		PhysicsImpostor.BoxImpostor,
    		{ mass: 0, restitution: 1 }
    	);

    	for (let i=0; i<5; i++) {

    	this.spheres[i] = MeshBuilder.CreateSphere('sphere', { diameter: 2 });
    	this.spheres[i].position = new Vector3((i*2)-12, 1, 0);
  
    	this.spheres[i].physicsImpostor = new PhysicsImpostor(
    		this.spheres[i],
    		PhysicsImpostor.SphereImpostor,
    		{ mass: 10, restitution: 1.5, friction: 1 }
    	);

    	this.spheres[i].material = this.CreateAshpalt();
    	
    	}
  
    	
    
    }

    async CreateRocket (): Promise<void> {

    	const { meshes } = await SceneLoader.ImportMeshAsync(
    		'',
    		'/models/',
    		'toon_rocket.glb',
    		this.scene
    	);

    	meshes[0].scaling = new Vector3(3, 3, 3);
    	meshes[0].rotate(new Vector3(0, 1, 0), Math.PI);
    	

    	const rocketCol = MeshBuilder.CreateBox('rocketCol', { width: 4, height: 5, depth: 2 });
    	rocketCol.position.y = 2;
    	rocketCol.visibility = 0;

    	rocketCol.physicsImpostor = new PhysicsImpostor(rocketCol, PhysicsImpostor.BoxImpostor, { mass: 1 });

    	meshes[0].setParent(rocketCol);
		
    	const disc = MeshBuilder.CreateDisc('disc', { radius: 1.5 }, this.scene);
    	
    	const decal = this.AddDadDecal(disc, new Vector3(0.8, 0.8, 0.8), new Vector3(0, 0, 0), Vector3.Forward(), 0.4);
    	disc.visibility = 0;
    	decal.setParent(disc);
    	disc.setParent(rocketCol);

    	disc.rotate(new Vector3(1, 0, 0), 0.18);
    	disc.position = new Vector3(0.043, 1.25, -1.26);
		

    	rocketCol.rotate(Vector3.Forward(), 0.3);

    	const rocketPhysics = () => {

    		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    		rocketCol.physicsImpostor!.setLinearVelocity(rocketCol.up.scale(2));
    		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    		rocketCol.physicsImpostor!.setAngularVelocity(rocketCol.up);
    		
            
    		this.camera.position = new Vector3(rocketCol.position.x, rocketCol.position.y+6, this.camera.position.z);
    	
    	};

    	

    	const gameOver = false;

    	if (!gameOver) this.scene.registerBeforeRender(rocketPhysics);

    	// this.scene.onPointerDown = () => {

    	// 	this.scene.unregisterBeforeRender(rocketPhysics);
    	//     gameOver = true;
    	
    	// };
    
    }

    CreateAshpalt (): PBRMaterial {

    	const pbr = new PBRMaterial('pbr', this.scene);
    	
    	pbr.albedoTexture = new Texture('./textures/asphalt/asphalt_diffuse.jpg', this.scene);
    	
    	pbr.bumpTexture = new Texture('./textures/asphalt/asphalt_normal.jpg', this.scene);
    	
    	pbr.invertNormalMapX = true;
    	pbr.invertNormalMapY = true;

    	pbr.useAmbientOcclusionFromMetallicTextureRed = true;
    	pbr.useRoughnessFromMetallicTextureGreen = true;
    	pbr.useMetallnessFromMetallicTextureBlue = true;

    	pbr.metallicTexture = new Texture('./textures/asphalt/asphalt_ao.jpg', this.scene);
    	// pbr.roughness = 1;

    	return pbr;
    
    }

}